import Loading from '@/components/loading';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Client, Gateway, Payment } from '@/types/schema';
import { Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloverPaymentDetails from '../pages/dashboard/commerce/payment/clover/details';

export function SavedCardComponent( {
	client,
	setCards,
	setCard,
	cardFirstSix,
	cardLastFour,
	setCardFirstSix,
	setCardLastFour,
	meteredType = 'Save Card',
	onSubmit,
}: {
	client: Client,
	setCards?: ( cards: any[] ) => void,
	setCard?: ( card: any ) => void,
	cardFirstSix: string,
	cardLastFour: string,
	setCardFirstSix: ( value: string ) => void,
	setCardLastFour: ( value: string ) => void,
	meteredType?: string,
	onSubmit: MutableRefObject<() => Promise<Payment>>
} ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	
	const [ isLoading, setIsLoading ] = useState( true );
	
	useEffect( () => {
		const timer = setTimeout( () => setIsLoading( false ), 1000 );
		return () => clearTimeout( timer );
		
	}, [] );
	
	return (
		<Stack spacing={1} sx={{ overflow: 'hidden !important' }}>
			{isLoading ? <Loading/> : (
				<CloverPaymentDetails
					gateway={client.gateway as Gateway}
					method='card'
					createPayment={async ( args ) => {
						if ( !args.cardId ) {
							enqueueSnackbar( t( 'common:card-save-warning' ), { variant: 'error' } );
							return;
						}
						if ( !args.cardId || !cardFirstSix || !cardLastFour ) {
							enqueueSnackbar( 'Save card info missing', { variant: 'error' } );
							return;
						}
						
						enqueueSnackbar( t( 'common:card-save-validating' ), { variant: 'info' } );
						try {
							const { data: savedCardData } = await axios.post( '/api/processor/payment/saveCard', {
								id          : client.id,
								cardId      : args.cardId,
								cardFirstSix: cardFirstSix,
								cardLastFour: cardLastFour,
								email       : client.email,
								meteredType,
							} );
							if ( savedCardData ) {
								setCard?.( savedCardData );
								const { data } = await axios.post( '/api/processor/payment/getCard', {
									id: client.id,
								} );
								if ( data?.cards?.elements && setCards ) {
									setCards( data.cards.elements.filter( ( card ) => card?.token?.startsWith( 'clv' ) ) );
								}
								enqueueSnackbar( t( 'common:card-save-success' ), { variant: 'success' } );
							}
						} catch {
							enqueueSnackbar( t( 'common:card-save-error' ), { variant: 'error' } );
							return;
						}
					}}
					onSubmit={onSubmit}
				/>
			)}
			<Stack direction='row' spacing={1}>
				<TextField
					fullWidth
					placeholder={t( 'common:card-first-six-placeholder' )}
					inputProps={{ maxLength: 6, minLength: 6 }}
					value={cardFirstSix}
					onChange={( e ) => setCardFirstSix( e.target.value )}
				/>
				<TextField
					fullWidth
					placeholder={t( 'common:card-last-four-placeholder' )}
					inputProps={{ maxLength: 4, minLength: 4 }}
					value={cardLastFour}
					onChange={( e ) => setCardLastFour( e.target.value )}
				/>
			</Stack>
		</Stack>
	);
}

export default function NewCardModal( { client, setCards, setCard }: {
	client: Client,
	setCards?: ( cards: any[] ) => void,
	setCard?: ( card: any ) => void
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = useRef<() => Promise<Payment>>();
	const [ cardFirstSix, setCardFirstSix ] = useState( '' );
	const [ cardLastFour, setCardLastFour ] = useState( '' );
	const { t } = useTranslation();
	
	return (
		<ResponsiveModalContainer
			title={t( 'common:save-new-card' )}
			secondaryTitle={`${t( 'common:save-new-card-pre' )} ${client?.email} ${t( 'common:save-new-card-post' )}`}
			saveButtonText={t( 'common:save-card' )}
			saveButtonProps={{
				disabled: cardFirstSix?.length !== 6 || cardLastFour?.length !== 4,
			}}
			onSave={async () => {
				console.log( 'wowww' );
				if ( onSubmit.current ) {
					let message;
					if ( cardFirstSix?.length !== 6 ) {
						message = t( 'common:card-first-six' );
					} else if ( cardLastFour?.length !== 4 ) {
						message = t( 'common:card-last-four' );
					}
					
					if ( message ) {
						enqueueSnackbar( message, { variant: 'info' } );
						return;
					}
				}
				await onSubmit?.current?.();
			}}>
			<SavedCardComponent
				client={client}
				cardFirstSix={cardFirstSix}
				cardLastFour={cardLastFour}
				setCardFirstSix={setCardFirstSix}
				setCardLastFour={setCardLastFour}
				setCards={setCards}
				setCard={setCard}
				onSubmit={onSubmit as MutableRefObject<() => Promise<Payment>>}
			
			/>
		</ResponsiveModalContainer>
	);
}
